import { graphql } from 'gatsby';
import * as React from 'react';

import { Page } from '../components/page';
import { HeroImage } from '../components/hero_image';
import { RichText } from '../components/richt_text';
import { TGatsbyImageFluid } from '../typedefs/gatsby-image';

import './impressum.scss';

type TImprintPageProps = {
  readonly data: {
    readonly heroImage: TGatsbyImageFluid;
    readonly heroImageMobile: TGatsbyImageFluid;
  };
};

const ImprintPage: React.FunctionComponent<TImprintPageProps> = ({ data }: TImprintPageProps) => {
  return (
    <Page title="Impressum">
      <RichText className="imprint">
        <h1>Impressum</h1>
        <p>
          <b>Für den Inhalt verantwortlich</b>
          <br />
          <b>Dr. Mario Sollhart</b>
          <br />
          Rechtsanwalt
          <br />
          Eingetragenes Mitglied der Treuhandrevision
          <br />
          Verteidiger in Strafsachen
          <br />
          R-Code: R603906
          <br />
          UID: ATU28233701
          <br />
          Gerichtsstand: Graz, Österreich
          <br />
          <br />
          <b>Anschrift</b>
          <br />
          Annenstraße 25/ III / 9-10
          <br />
          8020 Graz
          <br />
          Österreich
          <br />
          <br />
          <b>Kontakt</b>
          <br />
          Telefon: <a href="tel:004316832458">+43 316 832458</a>
          <br />
          E-Mail: <a href="mailto:recht@sollhart.at">recht@sollhart.at</a>
          <br />
          Fax: <a href="tel:0043168324584">+43 316 832458-4</a>
          <br />
          <br />
          <b>Kanzleikonto</b>
          <br />
          UniCredit Bank Austria AG
          <br />
          IBAN: AT08 1200 0100 3261 3126
          <br />
          BIC: BKAUATWW
        </p>
        <p className="attributions">
          Icons zur Verfügung gestellt von{' '}
          <a href="https://www.flaticon.com/" target="_blank" rel="noreferrer">
            Flaticon
          </a>{' '}
          (
          <a href="https://www.flaticon.com/authors/freepik" target="_blank" rel="noreferrer">
            Freepik
          </a>{' '}
          &{' '}
          <a href="https://www.flaticon.com/authors/wanicon" target="_blank" rel="noreferrer">
            wanicon
          </a>
          )
        </p>
      </RichText>
      <HeroImage
        image={data.heroImage}
        imageMobile={data.heroImageMobile}
        altText="Bücher"
        imgStyle={{ objectPosition: '0 0' }}
      />
    </Page>
  );
};

export default ImprintPage;

export const pageQuery = graphql`
  query ImprintPageQuery {
    heroImage: file(relativePath: { eq: "buecher.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    heroImageMobile: file(relativePath: { eq: "buecher.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
