import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from './container';
import { TGatsbyImageFluid } from '../typedefs/gatsby-image';

import './hero_image.scss';

export type THeroImageProps = {
  readonly image: TGatsbyImageFluid;
  readonly imageMobile?: TGatsbyImageFluid;
  readonly altText: string;
  readonly children?: React.ReactNode;
  readonly imgStyle?: Record<string, unknown>;
};

export const HeroImage: React.FunctionComponent<THeroImageProps> = ({
  image,
  imageMobile,
  altText,
  children,
  imgStyle = {},
}) => {
  return (
    <section className="hero">
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        className="hero_image desktop"
        alt={altText}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: '100% 50%',
          ...imgStyle,
        }}
      />
      <GatsbyImage
        image={imageMobile.childImageSharp.gatsbyImageData}
        className="hero_image mobile"
        alt={altText}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: '100% 50%',
          ...imgStyle,
        }}
      />
      <div className="hero_text">
        <Container>{children}</Container>
      </div>
    </section>
  );
};
